<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <h2 class="font-semibold text-gray-900 text-22px">
        <span v-if="isEditing">Edit Tax Pricing</span>
        <span v-else>Add Tax Pricing</span>
      </h2>
    </template>
    <div v-if="errors.length > 0" class="text-red-500">
      Error returned from server
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="px-6">
        <div class="flex items-center">
          <label class="block pr-2 w-1/2 font-semibold text-14px">
            Tax name
            <ValidationProvider
              name="Name"
              rules="required"
              v-slot="{ errors }"
            >
              <t-input name="taxName" v-model="form.name" />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </label>

          <label class="block pl-2 w-1/2 font-semibold text-14px">
            Tax Percentage
            <ValidationProvider
              name="Percentage"
              rules="required|between:0,100"
              v-slot="{ errors }"
            >
              <t-input name="taxPercentage" v-model="form.percentage" />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </label>
        </div>

        <label class="block mt-6 w-full font-semibold text-14px">
          Tax Description
          <t-textarea name="taxDescription" v-model="form.description" />
          <input-error-item />
        </label>
        <label class="block mt-6 w-full font-semibold text-14px">
          <TCheckbox
            v-model="form.status_active"
            :label="`Enable Tax Plan`"
            :name="`Enable Tax Plax`"
            wrapped
          />
        </label>

        <div class="flex justify-end items-center mt-6">
          <slide-over-right-trigger :id="sorId">
            <anchor-button variant="secondary">Cancel</anchor-button>
          </slide-over-right-trigger>
          <t-button type="submit" class="ml-3">{{
            isEditing ? `UPDATE` : `SAVE`
          }}</t-button>
        </div>
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { TaxConfig } from '@/config/TaxConfig'

import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'

export default {
  name: 'AddTaxPlan',
  components: {
    SlideOverRight,
    SlideOverRightTrigger,
    AnchorButton,
  },
  data() {
    return {
      isLoading: false,
      item: {},
      form: {},
      errors: [],
      sorId: TaxConfig.events.sorId,
    }
  },
  computed: {
    isEditing: function() {
      return Object.keys(this.item).length !== 0
    },
  },
  mounted() {
    console.log({ e: TaxConfig.events.editingData })

    EventBus.$on(TaxConfig.events.editingData, (item) => {
      this.item = item
      this.form = { ...this.item }
    })
    console.log({ s: this.isEditing })
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(TaxConfig.events.sorToggle))
    },
    submit: async function() {
      this.isLoading = true
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? TaxConfig.api.update(this.item.id)
        : TaxConfig.api.create
      let data = new xMan(this.form).toFormData()
      let message = this.isEditing
        ? 'Tax plan updated successfully'
        : 'Tax plan added successfully'

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(TaxConfig.events.sorClose))
        // Refetch the indexData
        dispatchEvent(new Event(TaxConfig.events.refresh))
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (error) {
        // todo: remove logs
        console.log(error)
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        this.errors.push('sadf')
        // Rejected
        // throw new Error(error.status);
      }
    },
  },
}
</script>

<style></style>
