export const TaxConfig = {
  api: {
    // get
    index: '/dashboard/tax-plans/',
    // post
    create: '/dashboard/tax-plans/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/tax-plans/${id}/`
    },
  },
  events: {
    name: 'tax-plan',
    // refresh-index-data
    refresh: `rid-tax-plan`,
    // slideover-right
    sorId: 'tax-plan',
    sorOpen: 'sor-open-tax-plan',
    sorClose: 'sor-close-tax-plan',
    sorToggle: 'sor-toggle-tax-plan',
    // editing-data
    editingData: 'edit-tax-plan-data',
    // viewing-data
    viewingData: 'view-tax-plan-data',
  },
}
