<template>
  <base-layout>
    <add-edit-tax-plan />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus title="Tax Management" @plus="add" />
          </div>
          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div>
        </div>

        <div
          class="flex flex-col mt-4 mb-10 space-y-4 md:space-y-0 md:items-center md:flex-row"
        >
          <card-tax-stat
            title="Number of taxes"
            :value="indexMetaData.summary.total"
            variant="green"
            class=""
          />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>{{ item.id | onlyLastFive }}</fsTableRowItem>

            <fsTableRowItem>{{ item.name }}</fsTableRowItem>

            <fsTableRowItem>{{ item.description }}</fsTableRowItem>

            <fsTableRowItem> {{ item.percentage }}% </fsTableRowItem>

            <fsTableRowItem>
              {{ item.created_at | friendlyDate }}
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <div @click="edit(item)">
                  <oto-edit-icon />
                </div>
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>

        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import OtoSearch from '@/components/ui/OtoSearch'
// import XStatus from "@/components/badge/XStatus";
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
// import MoreActionsDropdown from "@/components/dropdown/MoreActionsDropdown";

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { EventBus } from '@/utils/EventBus'
import { TaxConfig } from '@/config/TaxConfig'

import CardTaxStat from '@/components/cards/TaxStat'
import AddEditTaxPlan from './TaxPlanAddEdit'

export default {
  name: 'Tax',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    OtoSearch,
    DateRangePicker,
    CardTaxStat,
    // XStatus,
    ExportAsDropdown,
    // MoreActionsDropdown,
    AddEditTaxPlan,
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
        },
      },
      indexDataEndpoint: TaxConfig.api.index,
      tableHeaders: [
        {
          name: 'ID',
          width: '10%',
          field: 'name',
          sort: false,
          order: '',
        },
        {
          name: 'Name',
          width: '15%',
          field: 'name',
          sort: false,
          order: '',
        },
        {
          name: 'Description',
          width: '25%',
          field: 'description',
          sort: false,
          order: '',
        },
        {
          name: 'Percentage',
          width: '15%',
          field: 'percentage',
          sort: false,
          order: '',
        },
        {
          name: 'Created At',
          width: '20%',
          field: 'created_at',
          sort: true,
          order: '',
        },
        {
          name: 'Actions',
          width: '15%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  methods: {
    add: function() {
      EventBus.$emit(TaxConfig.events.editingData, {})
      dispatchEvent(new Event(TaxConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(TaxConfig.events.editingData, item)
      dispatchEvent(new Event(TaxConfig.events.sorToggle))
    },
  },
  async created() {
    // todo: remove the event listener on destroyed hook
    window.addEventListener(TaxConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
}
</script>
